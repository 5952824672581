//!* Custom
	.content.form {
		max-width: 848px;
		margin: 0 auto;
	}

	.subtitle {
		font-weight: bold;
		text-transform: uppercase;
		border-left: 4px solid $redrum-3;
		padding: 4px 0 4px 15px;
		color: $redrum-3;
		font-size: 1.15em;
		margin: ($spacing * 3) 0 ($spacing * 2);
	}